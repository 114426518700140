import { gql } from '@apollo/client';

export const FRAGMENT_TELNYX_METADATA = gql`
	fragment TelnyxIntegration on TelnyxPlatformType {
		name
		phoneNumber
		channelType
	}
`;

export const COMMUNICATION_CHANNEL_FRAGMENT = gql`
	fragment CommunicationChannel on CommunicationChannelType {
		id
		isPrimary
		isActive
		isDisabled
		createdAt
		platform {
			... on TwilioPlatformType {
				name
				phoneNumber
				accountSid
				phoneNumberSid
				isCustom
				channelType
			}
			... on SmoochTwilioPlatformType {
				name
				phoneNumber
				accountSid
				phoneNumberSid
				smoochId
				isCustom
				channelType
			}
			... on MessageBirdPlatformType {
				name
				phoneNumber
				channelType
			}
			... on SmoochMessageBirdPlatformType {
				name
				phoneNumber
				smoochId
				channelType
			}
			...TelnyxIntegration
		}
		digitalVoiceAssistant {
			id
			useCase
		}
	}

	${FRAGMENT_TELNYX_METADATA}
`;

export const FRAGMENT_COMMUNICATION_PROFILE = gql`
	fragment CommunicationProfile on CommunicationProfileType {
		id
		isActive
		createdAt
		platform {
			... on TelnyxProfileType {
				name
				channelType
			}
			... on TwilioProfileType {
				name
				channelType
				cnamDisplayName
				cnamTrustProductStatus
				shakenStirTrustProductStatus
				voiceIntegrityUseCase
				voiceIntegrityBusinessEmployeeCount
				voiceIntegrityAverageBusinessDayCallVolume
				voiceIntegrityNotes
				voiceIntegrityTrustProductStatus
			}
			... on CustomTwilioProfileType {
				name
				channelType
			}
		}
	}
`;

export const FRAGMENT_TWILIO_METADATA = gql`
	fragment TwilioIntegration on TwilioIntegrationType {
		isCustom
		phoneNumber
		accountSid
		phoneNumberSid
	}
`;

export const FRAGMENT_VOICE_CALL_SUMMARY = gql`
	fragment VoiceCallSummary on VoiceCallSummaryType {
		totalCalls
		totalInboundCalls
		totalOutboundCalls
		totalLiveTransferCalls

		callsThisInterval
		inboundCallsThisInterval
		outboundCallsThisInterval
		liveTransferCallsThisInterval
	}
`;

export const FRAGMENT_STRUCTURELY_ROI = gql`
	fragment StructurelyRoi on StructurelyRoiType {
		totalLeadsProcessed
		totalAppointmentsSet
		totalClosedWon
		pipelineInfluencedRevenue
		comissionInfluencedRevenue
		structurelyPayments
		roi
		appointmentSetToCloseRatio
		averageSellingPrice
		averageCommissionRate
		isBrokerage
	}
`;

export const FRAGMENT_STRIPE_SUBSCRIPTION_TYPE = gql`
	fragment StripeSubscription on SubscriptionType {
		plan
		currentLeadCount
		currentPeriodStart
		currentPeriodEnd
		basePlanType {
			id
			name
			domain
			amount
			pricingSchema
			billingInterval
			leadsPerInterval
			leadOverageCents
		}
	}
`;

export const FRAGMENT_RESELLER_SUBSCRIPTION_TYPE = gql`
	fragment ResellerSubscription on ResellerSubscriptionPlanType {
		resellerName
		currentLeadCount
		currentPeriodStart
		currentPeriodEnd
		billingInterval
		leadsPerInterval
		leadOverageCents
	}
`;
